<template>
  <div>
        <div class="header page_header">
            <div class="header-left-btn">
                <a href="profile_menu_page" 
                    class="appInnerLink backBtn" 
                    v-if="header_left_link"
                    @click="toPageLink(header_left_link,$event)">
                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                    width="94.000000pt" height="94.000000pt" viewBox="0 0 94.000000 94.000000"
                    preserveAspectRatio="xMidYMid meet">
                    <g transform="translate(0.000000,94.000000) scale(0.100000,-0.100000)"
                    fill="#fff" stroke="none">
                    <path d="M392 707 c-122 -122 -222 -229 -222 -237 0 -20 441 -460 461 -460 19
                    0 39 20 39 39 0 9 -91 107 -202 218 l-203 203 203 203 c111 111 202 209 202
                    218 0 19 -20 39 -40 39 -8 0 -115 -100 -238 -223z"/>
                    </g>
                    </svg>

                </a>
            </div>
            <div class="header-title">{{thisPageTitle}}</div>
            <div class="header-right-btn">
                <a class="appInnerLink" 
                href="add_avto_page"
                v-if="thisPageComponent_name=='avto_lists_page'"
                @click="toPageLink('add_avto_page',$event)">
                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                    width="128.000000pt" height="128.000000pt" viewBox="0 0 128.000000 128.000000"
                    preserveAspectRatio="xMidYMid meet">

                    <g transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)"
                    fill="#fff" stroke="none">
                    <path d="M607 1262 c-15 -16 -17 -52 -17 -295 l0 -277 -278 0 c-258 0 -280 -1
                    -295 -18 -22 -24 -21 -45 1 -65 16 -15 52 -17 295 -17 l277 0 0 -278 c0 -258
                    1 -280 18 -295 24 -22 45 -21 65 1 15 16 17 52 17 295 l0 277 278 0 c258 0
                    280 1 295 18 22 24 21 45 -1 65 -16 15 -52 17 -295 17 l-277 0 0 278 c0 258
                    -1 280 -18 295 -24 22 -45 21 -65 -1z"/>
                    </g>
                    </svg>

                </a>

            </div>
        </div>      
        <component :is="thisPageComponent_name" />
  </div>
</template>

<script>
import store from '../store';

import profile_menu_page from '../components/profile_menu_page.vue';
import notification_edit from '../components/notification_edit.vue';
import notifications_list from '../components/notifications_list.vue';
import profile_page from '../components/profile_page.vue';
import avto_lists_page from '../components/avto_lists_page.vue';
import avto_check_page from '../components/avto_check_page.vue';
import avto_registr_page from '../components/avto_registr_page.vue';
import callback_page from '../components/callback_page.vue';
import share_page from '../components/share_page.vue';
import login_page from '../components/login_page.vue';
import add_avto_page from '../components/add_avto_page.vue';



export default {
    store,
    components: {
        profile_menu_page,
        notification_edit,
        notifications_list,
        profile_page,
        avto_lists_page,
        avto_check_page,
        avto_registr_page,
        callback_page,
        login_page,
        share_page,
        add_avto_page
    },
    props: {
        isCompany: { type: Boolean, default: false },
    },
    beforeCreate() {
        store.commit('initialiseStore');
    },    
    computed: {
        thisPageComponent_name () {
            return store.state.active_page;
        },
        header_left_link () {
            if(this.thisPageComponent_name=='notification_edit') return 'profile_menu_page';
            if(this.thisPageComponent_name=='notifications_list') return 'profile_menu_page';
            if(this.thisPageComponent_name=='profile_page') return 'profile_menu_page';
            if(this.thisPageComponent_name=='avto_lists_page') return 'profile_menu_page';
            if(this.thisPageComponent_name=='callback_page') return 'profile_menu_page';
            if(this.thisPageComponent_name=='share_page') return 'profile_menu_page';
            if(this.thisPageComponent_name=='add_avto_page') return 'profile_menu_page';                  
            return false;
        },
        thisPageTitle(){
            if(this.thisPageComponent_name=='profile_menu_page') return 'Профиль';
            if(this.thisPageComponent_name=='notification_edit') return 'Настройка уведомлений';
            if(this.thisPageComponent_name=='notifications_list') return 'Уведомления';
            if(this.thisPageComponent_name=='profile_page') return 'Мои данные';
            if(this.thisPageComponent_name=='avto_lists_page') return 'Мои автомобили';
            if(this.thisPageComponent_name=='avto_check_page') return 'Проверить пропуск';
            if(this.thisPageComponent_name=='avto_registr_page') return 'Помощь в оформлении';
            if(this.thisPageComponent_name=='callback_page') return 'Связаться с нами';
            if(this.thisPageComponent_name=='login_page') return 'Вход/Регистрация';
            if(this.thisPageComponent_name=='share_page') return 'Рассказать друзьям';
            if(this.thisPageComponent_name=='add_avto_page') return 'Добавить авто';                        
            return store.state.active_page;            
        }

    },
    methods:{
        toPageLink(link,e) {

            e.preventDefault()
            console.log(e);
            // let href=this.getAttribute('href');
            console.log(link);
            // alert(href);
            store.commit('to_link_page', link);
            window.scrollTo({ top: 0 });
            document.querySelector('.app_wrap').scrollTo({ top: 0});
        }
    }
}
</script>