<template>
  <div>
    <div class="page_body">
      <div class="colorBlock" style="dispaly:none;">
        <div class="page_sub_title">МКАД ТТК СК</div>
        <div class="avto_check_text">
          <div class="avto_check_text-item">
            <p>От 3,5 тонн <br> день/ночь</p>
            <div class="avto_check_text-imgWrap">
              <img class="avto_check_text-img" src="../assets/img/gruz_3-5.png">
            </div>
          </div> 
          <div class="avto_check_text-item">
            <p>От 12 тонн <br> день/ночь</p>
            <div class="avto_check_text-imgWrap">
              <img class="avto_check_text-img" src="../assets/img/gruz_12.png">
            </div>
          </div> 
        </div>
      </div><br>
      <div>
        <avto_check_form></avto_check_form>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../store';
import axios from 'axios';
import avto_check_form from '../components/avto_check_form.vue';

export default {
    store,
    axios,
    props: {
        
    },
    components:{
      avto_check_form
    },
    data() {
      return {
        sitename:'https://openpolis.ru',
        avto_numb:'',
        avto_reg:'',
      }
    },    
    computed: {
      check_avto(){
        return store.state.check_avto;
      }
    },
    methods:{    
    }
}
</script>

<style>
    .callBack-input{
      color:#033481;
    }
    .check_avto_list-item{
      padding:10px;
      border:2px solid #033481;
      display:flex;
      flex-wrap:wrap;
    }
    .check_avto_list-item h3{
      width:100%;
    }
    .check_avto_list-item .std-label{
      margin-bottom:10px;
    }
    .char-value{
      color:#033481;
      font-size:19px;
    }
    .check_avto_list-cell{
      width:50%;
      width: 50%;
      margin-bottom: 30px;
    }
    .avto_check_text{
      text-align: center;
    }
    .avto_check_text-item{
      display: flex;
      justify-content: space-around;
    }
    .avto_check_text-img{
      max-width: 100%;
    }
    .avto_check_text-imgWrap{
      width:50%;
    }
</style>