<template>
    <div>
        <div class="page_body">
            <form class="loginForm" v-on:submit="handleSubmit" >
                <h3 class="page-h3-title">При помощи логина/пароля:</h3>
                <div class="form-item">
                    <label class="std-label">Логин:</label>
                    <input class="std-input"  v-model="login" type="text" name="name">
                </div>
                <div class="form-item">
                    <label class="std-label">Пароль:</label>
                    <input class="std-input" v-model="password" type="password" name="password">
                </div>
                <input type="submit" class="colorBtn" value="Войти">              
            </form>
            <form class="loginForm phoneLoginForm" v-on:submit="phoneLoginForm">
                <h3 class="page-h3-title">При помощи номера телефона:</h3>
                <div class="form-item">
                    <label class="std-label">Телефон</label>
                    <input class="std-input"  
                    v-model="phone" 
                    type="text" 
                    v-mask="'+7##########'"
                    :placeholder="'+70000000000'"
                    name="name">
                </div>
                <input type="submit" class="colorBtn" value="Получить код">
            </form>
            <form class="loginForm phoneLoginForm hidden" id="smsCodeForum" v-on:submit="smcCodeLoginForm" >
                <div class="form-item">
                    <label class="std-label">СМС-код</label>
                    <input class="std-input"  
                    v-model="smscode" 
                    type="text" 
                    v-mask="'####'"
                    required name="name">
                </div>
                <input type="submit" class="colorBtn" value="Войти">
            </form>            
        </div>
    </div>
</template>

<script>
import store from '../store';
import {mask} from 'vue-the-mask';
import axios from 'axios'
export default {
    store,
    axios,
    props: {
        
    },
    directives: {mask}, 
    data() {
      return {
        sitename:'https://openpolis.ru',
        login:'',
        password:'',
        phone:'',
        smscode:'',
        codeInput:false,
      }
    },
    computed: {
                
    },
    methods:{
      handleSubmit( e ) {
          e.preventDefault();
          axios.post( this.sitename + '/wp-json/jwt-auth/v1/token/',
          {
              username: this.login,
              password: this.password
          })
          .then(function (response) {
              if ( response.status === 200 ) {
                  const data = response.data;
                  console.log(data);
                  localStorage.setItem( 'login', data.token ); 
                  store.commit('logged_user', data);
                  
                  // this.get_full_user_info(data);
              }
          })          
          .catch(function (error) {
              function strip_html_tags(str) {
                  if ((str===null) || (str==='')){
                      return false;
                  }else{    
                    str = str.toString();
                    return str.replace(/<[^>]*>/g, '');
                }
              }
              console.log(error);
              alert( strip_html_tags( error.response.data.message ) );
          });
      },
      phoneLoginForm( e ) {
          e.preventDefault();
          axios.post( this.sitename + '/wp-json/avtopas/sendsmscode/',
          {
              phone: this.phone,
          })
          .then(function (response) {
              if ( response.status === 200 ) {
                  const data = response.data;
                  console.log(response.data);
                  alert(data.text);
                  document.querySelector('#smsCodeForum').classList.remove('hidden');
                  this.codeInput=data.status;
              }
          })          
          .catch(function (error) {
              function strip_html_tags(str) {
                  if ((str===null) || (str==='')){
                      return false;
                  }else{    
                    str = str.toString();
                    return str.replace(/<[^>]*>/g, '');
                }
              }
              console.log(error);
              alert( strip_html_tags( error.response.data.message ) );
          });
      }, 
      smcCodeLoginForm( e ) {
          e.preventDefault();
          axios.post( this.sitename + '/wp-json/avtopas/smccodeloginform/',
          {
              smscode: this.smscode,
              phone: this.phone
          })
          .then(function (response) {
              if ( response.status === 200 ) {
                    const data = response.data;
                    console.log(data);
                    if(data.token){
                        localStorage.setItem( 'login', data.token ); 
                        store.commit('logged_user', data);
                    }else{
                        alert(data);
                    }
              }
          })          
          .catch(function (error) {
              function strip_html_tags(str) {
                  if ((str===null) || (str==='')){
                      return false;
                  }else{    
                    str = str.toString();
                    return str.replace(/<[^>]*>/g, '');
                }
              }
              console.log(error);
              alert( strip_html_tags( error.response.data.message ) );
          });
      },       
      test() {
          alert(this.sitename + '/wp-json/jwt-auth/v1/token/');
      },      
    }
}
</script>