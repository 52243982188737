<template>
  <div>
      <div class="page_body">

       <div class="check_avto_list" v-if="avtoList">
          <!-- <div class="colorBtn"  @click="refresh_user_polices()">Проверить пропуска</div> -->
          <a href="avto_check_page" class="appInnerLink colorBtn subBtn" @click="toPageLink('avto_check_page',$event)" >Добавить новые пропуска</a>
          <h2>Ваши пропуска:</h2>
          <div  class="spinner_wrap active">
            <spinneranim></spinneranim>
          </div>
          
          <input type="text" class="callBack-input" placeholder="Поиск по номеру" v-model="search">
          <div class="app-notif-string" v-if="refresh_data_processing">Идёт проверка статусов пропусков</div>
          <div class="label-filter">Фильтр: {{labelFilterValue}}</div>
          <div class="polis_list_item" v-for="polis in avtoListSearch" :key="polis.ID">
                  <div class="polis_list_item-polisinfo">
                    <div class="label">Серия, номер:</div>
                    {{polis.polisSeries}}-{{polis.passInfo}}
                  </div>    
                  <div class="polis_list_item-polisinfo">
                    <div class="label">Зона:</div>
                    <div class="polis_list_item-data_block">{{polis.zone}}</div>
                  </div>
                  <div class="polis_list_item-polisinfo">
                    <div class="label">Срок действия:</div>
                    <div class="polis_list_item-data_block">c {{polis.dateStart}} по {{polis.dateEnd}} (включительно)</div>
                  </div>
                  <div class="polis_list_item-polisinfo" v-if="polis.polis_cancel">
                    <div class="label">Дата анулирования:</div>
                    <div class="polis_list_item-data_block">{{polis.polis_cancel}}</div>
                  </div>                    
                  <div class="polis_list_item-polisinfo imp">
                    <div class="label">Статус:</div>
                    <div class="polis_list_item-status">
                        {{polis.status}}
                    </div>
                  </div>
                  <div class="polis_list_item-polisinfo imp">
                    <div class="label">Осталось дней:</div>
                    <div class="polis_list_item-days_block">
                      <div class="polis_list_item-lastDays"><div v-if="12>0"> {{polis.leftDays}}</div></div>
                    </div>
                </div>                                   
            </div>
            <div v-if="avtoListSearch.length==0">
              <div class="polis_list_item"  v-for="polis in avtoList"  :key="polis.ID">
                  <div class="polis_list_item-polisinfo">
                    <div class="label">Серия, номер:</div>
                    {{polis.polisSeries}}-{{polis.passInfo}}
                  </div>    
                  <div class="polis_list_item-polisinfo">
                    <div class="label">Зона:</div>
                    <div class="polis_list_item-data_block">{{polis.zone}}</div>
                  </div>
                  <div class="polis_list_item-polisinfo">
                    <div class="label">Срок действия:</div>
                    <div class="polis_list_item-data_block">c {{polis.dateStart}} по {{polis.dateEnd}} (включительно)</div>
                  </div>
                  <div class="polis_list_item-polisinfo" v-if="polis.polisCanceledData">
                    <div class="label">Дата анулирования:</div>
                    <div class="polis_list_item-data_block">{{polis.polisCanceledData}}</div>
                  </div>                  
                  <div class="polis_list_item-polisinfo imp">
                    <div class="label">Статус:</div>
                    <div class="polis_list_item-status">
                        {{polis.status}}
                    </div>
                  </div>
                  <div class="polis_list_item-polisinfo imp">
                    <div class="label">Осталось дней:</div>
                    <div class="polis_list_item-days_block">
                      <div class="polis_list_item-lastDays"><div v-if="12>0"> {{polis.leftDays}}</div></div>
                    </div>
                </div>                                     
              </div>

            </div>
        </div>


      </div>
  </div>
</template>

<script>
import store from '../store';
import axios from 'axios';
import spinneranim from '../components/spinner.vue';
export default {
    store,
    axios,
    spinneranim,
    components:{
    },    
    data() {
      return {
        sitename:'https://openpolis.ru',
        login:'',
        password:'',
        search: '',
        loading:false,
      }
    },
    computed: {
        logged_user_id() {
            return store.state.active_user.full_info.id;
        },
        refresh_data_processing() {
            return store.state.refreshDataProcessing;
        },        
        labelFilterValue(){
          if(this.avtoListSearch.length==0){
            return  'все ('+this.avtoList.length+')';
          }else{
            return  this.avtoListSearch.length+' ('+this.avtoList.length+')';
          }
        },
        avtoList() {
            return store.state.user_avtos;
        },
        avtoListSearch() {
            let startList=store.state.user_avtos;
            let search=this.search;
            console.log(search);
            let inputList=startList.filter((element) => {
              let res=false;
              if(search!=''){
                if(element.post_title.toLowerCase().indexOf(search.toLowerCase())!=-1)res=true;
                if(element.passInfo.toLowerCase().indexOf(search.toLowerCase())!=-1)res=true;
                if(element.polisSeries.toLowerCase().indexOf(search.toLowerCase())!=-1)res=true;
                return res;
              }else{return false;}
            } );
            return inputList;
        }        
    },
    methods:{
        refresh_user_polices(){
              const userURI = this.sitename + '/wp-json/avtopas/getUsrAvto';
        
                axios.post( userURI,
                {
                    user_id: this.logged_user_id,
                
              }).then(function (response) {
                  if ( response.status === 200 ) {
                      const data = response.data;
                      // console.log(data);
                      let avtos=JSON.parse(data);
                      avtos=JSON.parse(avtos);
                      // let avtos=data;
                      console.log(avtos);
                      
                      avtos.forEach(avto => {
                        let nowStamp=Date.now();
                        let endStamp=new Date(avto.dateEnd).getTime();
                        let perStamp=endStamp-nowStamp;
                        console.log(avto.dateEnd);
                        console.log(perStamp);
                        let days=Math.floor(perStamp/86400000);
                        console.log(days);
                        avto.leftDays=days;
                      });


                      store.commit('set_avto_list',avtos);
                      store.commit('set_refresh_data_mode',true);
                      console.log('set_avto_list');
                      setTimeout(() => { 
                        store.commit('set_refresh_data_mode',true);
                        console.log('check_polis_mosdata');
                        store.commit('check_polis_mosdata',avtos); 
                      }, 2000);
                      
                  }
              })
              .catch(function (error) {
                   console.log(error);
              });
        },
        toPageLink(link,e) {

            e.preventDefault()
            console.log(e);
            // let href=this.getAttribute('href');
            console.log(link);
            // alert(href);
            store.commit('to_link_page', link);
            window.scrollTo({ top: 0 });
            if(document.querySelector('.app_wrap'))document.querySelector('.app_wrap').scrollTo({ top: 0}); 
            
        },        
    },
    mounted() {
        const userURI = this.sitename + '/wp-json/avtopas/getUsrAvto';
        
          axios.post( userURI,
          {
              user_id: this.logged_user_id,
          
        }).then(function (response) {
            if ( response.status === 200 ) {
                const data = response.data;
                // console.log(data);
                let avtos=JSON.parse(data);
                avtos=JSON.parse(avtos);
                // let avtos=data;
                console.log(avtos);
                
                avtos.forEach(avto => {
                  let nowStamp=Date.now();
                  let endStamp=new Date(avto.dateEnd).getTime();
                  let perStamp=endStamp-nowStamp;
                  console.log(avto.dateEnd);
                  console.log(perStamp);
                  let days=Math.floor(perStamp/86400000);
                  console.log(days);
                  avto.leftDays=days;
                });


                store.commit('set_avto_list',avtos);
                
                console.log('set_avto_list');

                 
            }
        })
        .catch(function (error) {
             console.log(error);
        });

    },
    props: {
        
    },
}
</script>

<style>
    
  .callBack-input{
        color:#033481;
  }
  .polis_list_item{
    color: #3f6830;
    background-color: #cacefe;
    padding: 10px;
    margin-bottom: 20px;
    border: none;
    font-size: 18px;
    max-width: 300px;
    margin:10px auto;
  
  }
  .polis_list_item.new_item{
    background-color: #c7fae4;
    border: 3px solid #00ff47;
    position:relative;
  }  
  .polis_list_item-header{
      border-bottom: 1px solid #b4bce0;
      padding-bottom: 3px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 5px;
  }
  .polis_list_item-avto{
        font-size: 21px;
        text-transform: uppercase;
        display: flex;
        justify-content: space-between; 
  }
  .polis_list_item-polis_zone{
        font-size: 21px;
        text-transform: uppercase;
  }
  .polis_list_item-avto_number{
    margin-right: 5px;
  }
  .polis_list_item-avto_region{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .polis_list_item-polis-title{
        color: #20a5f0;
        display: flex;
        justify-content: space-between;
        width: 100%; 
        margin-bottom: 5px;
  }
  .polis_list_item-timeType{
    
  }

  .polis_list_item-polis_number{
    text-align:left;
  }
  .polis_list_item-days_block{
      text-align: center;
  }
  .polis_list_item-lastDays{
  }
  .polis_list_item-period-type{
        color: #000;
        /* font-weight: 700; */
  }
  .polis_list_item-data_block{
        /* font-size: 12px; */
  }
  .polis_list_item-status{
    text-align: center;
    font-size: 23px;
    text-transform: uppercase;
    padding: 5px 0;
  }
  .polis_list_item-status.load{
    position:relative;
    color:transparent;
  }
  .polis_list_item-status.load:after{
    background: linear-gradient(180deg, #9eb9f5 0%, rgb(3 52 129 / 49%) 100%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: block;
    left: 30%;
    position: absolute;
    -webkit-animation: reflection 1.5s infinite;
    animation: reflection 1.5s infinite;
    /* transform: skewX(338deg); */
    content: '';
    top: calc(50% - 5px);
  }  
  .label-filter{
    color: #033481;
    text-align: center;
    font-size: 18px;
    margin-bottom: 30px;
  }
  .app-notif-string{
    text-align:center;
    padding:20px 10px;
  }
  @keyframes reflection{
    50% {
        display: none;
        left: 61%;
    }
    100% {
        display: none;
        left: 33%;
    }    
  }

</style>