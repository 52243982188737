<template>
  <div>
      <div class="page_body">
        <form class="callBackForm">
            <div class="form-item">
                <label class="label">Ваше имя:</label>
                <input class="callBack-input" type="text" name="name">
            </div>
            <div class="form-item">
                <label class="label">Ваш телефон:</label>
                <input class="callBack-input" type="phone" name="phone">
            </div>
            <div class="form-item">
                <label class="label">Ваше сообщение:</label>
                <textarea class="callBack-input" name="message">Ваше сообщение</textarea>
            </div>
            <input type="submit" class="colorBtn" value="Отправить">
        </form>
  </div>
  </div>
</template>

<script>
import store from '../store';
export default {
    store,
    props: {
        
    },
    computed: {
                
    }
}
</script>

<style>
    .callBackForm{
        font-size:21px;
    }
    .callBackForm .label{
        font-size:19px;
        display:block;
        text-align:center;
        margin-bottom:20px;
    }
    .callBack-input{
      list-style:none;
      margin-bottom:60px;
        padding: 20px 25px;
      font-size:21px;
      color:#033481;
      border:2px solid #033481;
      margin-bottom:20px;
      width:100%;
      box-sizing: border-box;
    }
</style>