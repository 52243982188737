<template>
    <section class="footer">
        <nav class="footer_navi">
            <ul class="footer_navi_ul">
                <li class="footer_navi_item">
                    <a href="profile_menu_page" class="appInnerLink footer_navi_link">
                        <div class="icon-wrap">
                            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                                preserveAspectRatio="xMidYMid meet">

                                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                fill="#fff" stroke="none">
                                <path d="M2335 5105 c-273 -42 -517 -172 -708 -375 -210 -223 -319 -481 -334
                                -790 -21 -432 191 -845 557 -1084 180 -118 353 -178 563 -195 389 -30 721 92
                                989 365 262 267 379 596 347 974 -28 324 -171 603 -419 817 -196 168 -376 252
                                -625 288 -120 17 -260 18 -370 0z m426 -314 c157 -40 275 -109 407 -236 172
                                -163 259 -343 282 -576 27 -276 -58 -528 -245 -728 -116 -124 -287 -229 -432
                                -266 -215 -54 -471 -31 -655 60 -251 124 -437 356 -505 630 -26 105 -23 342 6
                                450 77 290 329 559 609 650 152 49 379 56 533 16z"/>
                                <path d="M1427 2639 c-153 -16 -311 -74 -436 -158 -312 -212 -514 -686 -561
                                -1316 -26 -354 -5 -529 86 -712 50 -102 145 -214 242 -286 74 -54 198 -110
                                307 -138 l90 -24 1400 0 1400 0 90 23 c316 83 537 296 616 597 41 155 36 525
                                -12 817 -105 650 -371 1040 -792 1162 -96 28 -268 49 -319 39 -49 -9 -117 -45
                                -271 -144 -166 -107 -192 -121 -316 -166 -146 -53 -255 -74 -390 -74 -140 0
                                -248 20 -390 70 -134 48 -140 51 -350 184 -110 70 -192 115 -224 124 -56 14
                                -56 14 -170 2z m260 -395 c188 -121 267 -160 432 -214 430 -141 869 -73 1283
                                198 187 123 183 121 262 111 363 -46 590 -351 686 -924 39 -236 51 -565 25
                                -690 -42 -201 -178 -343 -386 -402 -60 -17 -139 -18 -1434 -18 -1298 0 -1373
                                1 -1435 18 -149 42 -278 144 -338 267 -52 106 -65 195 -57 410 7 217 17 318
                                52 495 26 138 87 334 130 419 129 256 304 396 531 425 37 5 71 9 75 10 4 0 82
                                -47 174 -105z"/>
                                </g>
                            </svg>

                        </div>
                        Кабинет
                    </a>
                </li>
                <li class="footer_navi_item">
                    <a href="avto_check_page" class="appInnerLink footer_navi_link">
                        <div class="icon-wrap">
                            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                            width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                            preserveAspectRatio="xMidYMid meet">

                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                            fill="#fff" stroke="none">
                            <path d="M1851 5109 c-657 -74 -1219 -432 -1555 -989 -138 -229 -223 -463
                            -273 -745 -26 -151 -23 -500 5 -655 120 -657 508 -1187 1087 -1487 310 -160
                            591 -228 945 -227 431 0 785 107 1145 345 l119 79 696 -693 c469 -468 708
                            -700 735 -713 54 -25 167 -25 215 0 120 63 175 193 135 317 -17 51 -54 91
                            -717 755 l-699 701 77 114 c371 548 453 1234 222 1869 -75 208 -219 450 -375
                            631 -260 300 -628 533 -1005 634 -230 62 -542 89 -757 64z m357 -510 c380 -41
                            677 -182 943 -448 271 -271 415 -579 451 -963 32 -343 -60 -693 -263 -998 -84
                            -127 -282 -325 -409 -409 -512 -341 -1143 -359 -1664 -47 -376 224 -641 602
                            -728 1039 -32 160 -32 414 0 574 90 451 367 835 767 1062 267 152 604 223 903
                            190z"/>
                            </g>
                            </svg>
                        </div>
                        Проверить
                    </a>
                </li>
                <li class="footer_navi_item">
                    <a href="avto_registr_page" class="appInnerLink footer_navi_link">
                        <div class="icon-wrap">
                            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                                preserveAspectRatio="xMidYMid meet">

                                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                fill="#fff" stroke="none">
                                <path d="M94 5105 c-42 -19 -82 -68 -90 -112 -5 -28 68 -392 316 -1572 178
                                -845 328 -1550 335 -1567 7 -17 28 -43 46 -56 19 -14 522 -220 1119 -458 597
                                -238 1087 -435 1089 -437 3 -2 -36 -45 -86 -96 -98 -99 -112 -129 -98 -204 5
                                -33 44 -76 283 -315 299 -299 300 -300 390 -283 33 6 130 100 874 843 743 744
                                837 841 843 875 17 89 16 90 -283 389 -239 239 -282 278 -314 283 -76 14 -106
                                0 -205 -98 -51 -50 -94 -89 -96 -86 -2 2 -201 497 -441 1099 -301 752 -446
                                1104 -463 1122 -15 15 -40 32 -57 38 -57 18 -3078 650 -3104 649 -15 0 -41 -7
                                -58 -14z m1780 -669 c664 -140 1209 -256 1211 -258 1 -2 201 -502 445 -1112
                                l443 -1108 -406 -405 -405 -406 -1108 443 c-610 244 -1110 444 -1112 445 -3 3
                                -512 2414 -512 2425 0 3 242 -237 538 -533 l539 -539 -25 -61 c-147 -363 126
                                -767 518 -767 358 0 627 340 545 689 -78 329 -439 516 -752 389 l-61 -25 -539
                                539 c-296 296 -536 538 -533 538 3 0 549 -115 1214 -254z m200 -1088 c55 -16
                                138 -99 154 -154 28 -94 8 -169 -63 -239 -51 -52 -102 -75 -165 -75 -124 0
                                -240 116 -240 240 0 63 23 114 75 165 70 71 145 90 239 63z m2536 -1463 l125
                                -125 -688 -688 -687 -687 -127 128 -128 127 685 685 c377 377 687 685 690 685
                                3 0 61 -56 130 -125z"/>
                                </g>
                                </svg>
                        </div>
                        Оформить
                    </a>
                </li>
                
            </ul>
        </nav>
    </section>
</template>

<script>
    export default {
        name:'footer_navi',
        data(){
            return{
                
            }         
        },
        computed: {
            background:function() {
                return {backgroundImage: 'background'}
            }
        }   
    }
</script>
<style scoped>
    .icon-wrap{
        width: 30px;
        height: 30px;
        margin-bottom: 5px;
    }
    .icon-wrap svg{
        width: 100%;
        height: 100%;
        object-fit: contain;
        margin-bottom: 10px;
    }    
</style>
