<template>
  <div>
      <div class="page_body">
        <h2>Рассказать друзьям</h2>
      </div>
  </div>
</template>

<script>
import store from '../store';
export default {
    store,
    props: {
        
    },
    computed: {
                
    }
}
</script>

<style>
    .callBack-input{
      color:#033481;
    }
</style>