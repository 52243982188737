<template>
  <div id="app">
    <!-- <div id="desctopMode"></div> -->
    <div v-if="mode=='mobile'">
      <div class="app_wrap">
        <pageContent/>
        <footerNavi/>
      </div>
    </div>
    <div v-if="mode=='desktop'">
      <div class="app_wrap_desc">
        <pageDescContent/>
      </div>
    </div>

  </div>
</template>

<script>
import store from './store';
import pageContent from './components/page_content.vue';
import footerNavi from './components/footer_navi.vue';
import pageDescContent from './components/desc_page_content.vue';



export default {
  name: 'App',
  store,
  components: {
    pageContent,
    footerNavi,
    pageDescContent,
  },
  data() {
    return {
      name: 'Vue.js',
      // mode:'mobile'
    }
  },
  computed: {
      mode(){
        let versionMode=document.querySelector('#desctopMode');
        console.log(versionMode);
        if(versionMode){
          store.commit('change_app_mode','desktop');
          store.commit('to_link_page', 'avto_check_page');
        }else{
          store.commit('change_app_mode','mobile');
        }
        return store.state.app_mode;
      }
  },  
  methods: {

  }
}
</script>

<style lang="css" src="./assets/base_styles.css"></style>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
