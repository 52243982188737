<template>
  <div>
        <div class="page_body page_body_desc">
            <div class="page_body_content">
                    <div class="header page_header">
                        <div class="header-left-btn">
                            
                        </div>
                        <div class="header-title">{{thisPageTitle}}</div>
                        <div class="header-right-btn">
                            <a class="appInnerLink" 
                            href="add_avto_page"
                            v-if="thisPageComponent_name=='avto_lists_page'"
                            @click="toPageLink('add_avto_page',$event)">
                                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                width="128.000000pt" height="128.000000pt" viewBox="0 0 128.000000 128.000000"
                                preserveAspectRatio="xMidYMid meet">

                                <g transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)"
                                fill="#fff" stroke="none">
                                <path d="M607 1262 c-15 -16 -17 -52 -17 -295 l0 -277 -278 0 c-258 0 -280 -1
                                -295 -18 -22 -24 -21 -45 1 -65 16 -15 52 -17 295 -17 l277 0 0 -278 c0 -258
                                1 -280 18 -295 24 -22 45 -21 65 1 15 16 17 52 17 295 l0 277 278 0 c258 0
                                280 1 295 18 22 24 21 45 -1 65 -16 15 -52 17 -295 17 l-277 0 0 278 c0 258
                                -1 280 -18 295 -24 22 -45 21 -65 -1z"/>
                                </g>
                                </svg>

                            </a>

                        </div>
                    </div> 
                <div v-if="isLogged_user">
                    <component :is="thisPageComponent_name" />
                </div>
                <div class="" v-else>
                    <avto_check_page></avto_check_page>
                </div>
            </div>
            <div class="page_body_sidebar">
                <div v-if="isLogged_user">
                    <profile_menu_page></profile_menu_page>
                </div>
                <div class="" v-else>
                    <div class="std-tab">
                        <div class="std_tab-header">
                            <div class="std_tab-header-item"
                            v-on:click="active_tab='login'"
                            :class="{active:active_tab=='login'}"
                            >Войти</div>
                            <div class="std_tab-header-item"
                            v-on:click="active_tab='registr'"
                            :class="{active:active_tab=='registr'}"
                            >Регистрация</div>
                        </div>
                        <div class="std_tab-body">
                            <div class="std_tab-body-item"  v-if:="active_tab=='login'">
                                <h3>Войти</h3>
                                <login_form></login_form>
                            </div>
                            <div class="std_tab-body-item" v-if:="active_tab=='registr'">
                                <h3>Регистрация</h3>
                                <registr_form></registr_form>
                            </div>                    
                        </div>
                    </div>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import store from '../store';

import profile_menu_page from '../components/profile_menu_page.vue';
import notification_edit from '../components/notification_edit.vue';
import notifications_list from '../components/notifications_list.vue';
import profile_page from '../components/profile_page.vue';
import avto_lists_page from '../components/avto_lists_page.vue';
import avto_check_page from '../components/avto_check_page.vue';
import avto_registr_page from '../components/avto_registr_page.vue';
import callback_page from '../components/callback_page.vue';
import share_page from '../components/share_page.vue';
import login_page from '../components/login_page.vue';
import add_avto_page from '../components/add_avto_page.vue';

import login_form from '../components/login_form.vue';
import registr_form from '../components/registr_form.vue';

export default {
    store,
    components: {
        profile_menu_page,
        notification_edit,
        notifications_list,
        profile_page,
        avto_lists_page,
        avto_check_page,
        avto_registr_page,
        callback_page,
        login_page,
        share_page,
        add_avto_page,
        login_form,
        registr_form
    },
    props: {
        isCompany: { type: Boolean, default: false },
    },
    data() {
      return {
        sitename:'https://openpolis.ru',
        login:'',
        password:'',
        active_tab:'login'
      }
    },    
    computed: {
        thisPageComponent_name () {
            return store.state.active_page;
        },
        isLogged_user() {
            console.log(store);
            return store.state.active_user;
        },
        user_active_notifs(){
            if(store.state.active_user.full_info){
                if(store.state.active_user.full_info.acf.user_notifications){
                    let notifs=JSON.parse(store.state.active_user.full_info.acf.user_notifications);
                    let notViews=0
                    notifs.forEach(item => {
                        if(!item.view)notViews++;
                    });
                    return notViews;
                }else{
                    return false;
                }
            }else{
                return false;
            }
        },
        thisPageTitle(){
            if(this.thisPageComponent_name=='profile_menu_page') return 'Профиль';
            if(this.thisPageComponent_name=='notification_edit') return 'Настройка уведомлений';
            if(this.thisPageComponent_name=='notifications_list') return 'Уведомления';
            if(this.thisPageComponent_name=='profile_page') return 'Мои данные';
            if(this.thisPageComponent_name=='avto_lists_page') return 'Мои автомобили';
            if(this.thisPageComponent_name=='avto_check_page') return 'Проверить пропуск';
            if(this.thisPageComponent_name=='avto_registr_page') return 'Помощь в оформлении';
            if(this.thisPageComponent_name=='callback_page') return 'Связаться с нами';
            if(this.thisPageComponent_name=='login_page') return 'Вход/Регистрация';
            if(this.thisPageComponent_name=='share_page') return 'Рассказать друзьям';
            if(this.thisPageComponent_name=='add_avto_page') return 'Добавить авто';                        
            return store.state.active_page;            
        }
    },
    beforeCreate() {
        store.commit('initialiseStore');
    },    
    methods:{
        logout(){
            store.commit('loggout_user');
        },
        toPageLink(link,e) {

            e.preventDefault()
            console.log(e);
            // let href=this.getAttribute('href');
            console.log(link);
            // alert(href);
            store.commit('to_link_page', link);
            window.scrollTo({ top: 0 });
            document.querySelector('.app_wrap').scrollTo({ top: 0});
        },
        sharedInfo(e) {

            e.preventDefault()
            if (navigator.share) {
                console.log("Congrats! Your browser supports Web Share API")

                // navigator.share принимает объект с URL, title или text
                navigator.share({
                        title: "Openpolis.ru",
                        text: 'Openpolis.ru. Оформление пропуска в Москву (МКАД, ТТК, СК)',
                        url: window.location.href
                    })
                    .then(function () {
                        console.log("Shareing successfull")
                    })
                    .catch(function () {
                        console.log("Sharing failed")
                    })

            } else {
                console.log("Sorry! Your browser does not support Web Share API")
            }
            
        }        
    }
}
</script>
<style>
    .page_body_desc{
        display: flex;
    }
    .page_body_sidebar{
        width: 30%;
    }    
    .page_body_content{
        width: 70%;
    }    
</style>