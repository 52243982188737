<template>
  <div>
      <div class="page_body">
        <div class="notif_list-wrap">
          <ul class="notif_list">
            <li class="notif_list-item" 
              v-for="(notif, index) in user_notifs" 
              :key="notif.date" 
              v-bind:class="{ notView: !notif.view }"
              v-on:click="seeNotif(index)" >
              <div class="notif_list_item-icon">!</div>
              <div class="notif_list_item-body">
                <div class="notif_list_item-title">{{notif.title}}</div>
                <div class="notif_list_item-text">{{notif.text}}</div>
                <div class="notif_list_item-date">{{notif.time}}</div>
                <div class="notif_list_item-del"
                   v-on:click="dell_notif(index)"
                  >x</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
  </div>
</template>

<script>
import store from '../store';
import axios from 'axios';
export default {
    store,
    axios,
    props: {
        
    },
    computed: {
      active_user(){
          return store.state.active_user;
      },
      user_notifs(){
          let list=JSON.parse(store.state.active_user.full_info.acf.user_notifications);
          list.forEach(function(item,index){
            let date=new Date(item.time * 1000);
            date=date.toLocaleString();
            list[index].time=date;
          });
          return list;
      },
      user_active_notifs(){
          let notifs=JSON.parse(store.state.active_user.full_info.acf.user_notifications);
          let notViews=0
          notifs.forEach(item => {
            if(!item.view)notViews++;
          });
          return notViews;
      }
    },
    methods:{
      seeNotif(index){
        store.commit('ses_notif', index);
        // axios.post( this.sitename + '/wp-json/avtopas/notifrefresh',
        //   {
        //       userID: this.active_user.full_info.id
        //       userID: this.active_user.full_info.id
        //   })
        //   .then(function (response) {
        //       if ( response.status === 200 ) {
        //           const data = response.data;
        //           console.log(data);
        //           localStorage.setItem( 'login', data.token ); 
        //           store.commit('logged_user', data);
                  
        //           // this.get_full_user_info(data);
        //       }
        //   })          
        //   .catch(function (error) {
        //       function strip_html_tags(str) {
        //           if ((str===null) || (str==='')){
        //               return false;
        //           }else{    
        //             str = str.toString();
        //             return str.replace(/<[^>]*>/g, '');
        //         }
        //       }
        //       console.log(error);
        //       alert( strip_html_tags( error.response.data.message ) );
        //   });
      },
      dell_notif(index){
        store.commit('dell_notif', index);
      },
      editHar(harname){
        console.log(harname);
        let payload={
          userInfo:this.active_user,
          harname:harname
        };
        store.commit('edit_hars_user', payload);
      }      
    }
}
</script>

<style>
    .notif_list{
      padding-left: 0px;
    }
    .notif_list-wrap{
      display: flex;
      flex-direction: column;
    }

    .notif_list-item{
      display: flex;
      word-break: break-word;
      border-bottom: 1px solid #033481;
      padding: 10px 15px;
      position:relative;      
    }
    .notView.notif_list-item{
      background-color: #bad1f6;
      font-weight: 700;

    }
    
    .notif_list_item-icon{
      width: 30px;
      color: #033481;
      font-size: 45px;
    }
    .notif_list_item-body{
      width: calc(100% - 40px);
      display: flex;
      flex-direction: column;      
    }
    .notif_list_item-title{
      color: #033481;
      font-size: 21px;
      margin-bottom: 10px;
    }

    .notif_list_item-text{

    }
    .notif_list_item-date{
      text-align: right;
      margin-top: 10px;
      font-size: 15px;
      color: #727272;
    }
    .notif_list_item-del{
      position:absolute;
      top:10px;
      right:10px;
      font-size: 18px;
      cursor: pointer;
      padding: 5px 10px;
      color: #adb0b3;      
    }
</style>