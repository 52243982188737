<template>
  <div>

    <div class="page_body">
      <div>
        <avto_check_form></avto_check_form>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../store';
import axios from 'axios';
import avto_check_form from '../components/avto_check_form.vue';

export default {
    store,
    axios,
    props: {
        
    },
    components:{
      avto_check_form
    },
    data() {
      return {
        sitename:'https://openpolis.ru',
        avto_numb:'',
        avto_reg:'',
      }
    },    
    computed: {
      check_avto(){
        return store.state.check_avto;
      }
    },
    methods:{    
    }
}
</script>

<style>
    .callBack-input{
      color:#033481;
    }
    .check_avto_list-item{
      padding:10px;
      border:2px solid #033481;
      display:flex;
      flex-wrap:wrap;
    }
    .check_avto_list-item h3{
      width:100%;
    }
    .check_avto_list-item .std-label{
      margin-bottom:10px;
    }
    .char-value{
      color:#033481;
      font-size:19px;
    }
    .check_avto_list-cell{
      width:50%;
      width: 50%;
      margin-bottom: 30px;
    }
</style>