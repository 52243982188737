import { createApp } from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import store from './store'


// import pageContent from './components/page_content.vue';
// import footerNavi from './components/footer_navi.vue';
// import profileMenuPage from './components/profile_menu_page.vue';
// import notificationEdit from './components/notification_edit.vue';
// import notificationsList from './components/notifications_list.vue';
// import profilePage from './components/profile_page.vue';
// import avtoListsPage from './components/avto_lists_page.vue';
// import avtoCheckPage from './components/avto_check_page.vue';
// // import loginPage from './components/login_page.vue ';
// import avtoRegistrPage from './components/avto_registr_page.vue';
// import callbackPage from './components/callback_page.vue';





createApp(App).use(store).mount('#app');


// store.commit('to_link_page', 'profile_page');

let appInnerLinks=document.querySelectorAll('#app a.appInnerLink');
console.log(appInnerLinks);
if(appInnerLinks){
    appInnerLinks.forEach(link => {
        link.addEventListener('click',function(e){
            e.preventDefault();
            let href=link.getAttribute('href');
            store.commit('to_link_page', href);
            window.scrollTo({ top: 0 });
            if(document.querySelector('.app_wrap'))document.querySelector('.app_wrap').scrollTo({ top: 0});
        });
    });
}

let pageHeight=window.innerHeight;
document.querySelector('#app').setAttribute('style','min-height:'+pageHeight+'px!important;');







// let app=document.querySelector('#app');
// if(app){
//     app.addEventListener('click',function(e){
//         console.log(this);
//         alert(this.classList.contains('appInnerLink'));
//         if(this.classList.contains('appInnerLink')){  
//             e.preventDefault();
//             let link=e.target;
//             let href=link.getAttribute('href');
//             store.commit('to_link_page', href);
//         }
//     },true)
// }

let platform = {};

function checkPlatform() {

    // browser info and capability
    var _ua = window.navigator.userAgent;

    
    platform.isIDevice = ( /iphone|ipod|ipad/i ).test( _ua );
    platform.isAndroid = /android/i.test( _ua );
    platform.isWindowsPhone = /Windows Phone/i.test( _ua );
    
    platform.isSamsung = /Samsung/i.test( _ua );
    platform.isChrome = /chrome/i.test( _ua );
    platform.isFireFox = /Firefox/i.test( _ua );
    platform.isOpera = /opr/i.test( _ua );
    platform.isEdge = /edg/i.test( _ua );
    platform.isInApp = /cordova/i.test( _ua );
    
    // Opera & FireFox only Trigger on Android
    if ( platform.isFireFox ) {
        platform.isFireFox = /android/i.test( _ua );
    }

    if ( platform.isOpera ) {
        platform.isOpera = /android/i.test( _ua );
    }

    platform.isInWebAppiOS = ( window.navigator.standalone === true );
    platform.isInWebAppChrome = ( window.matchMedia( '(display-mode: standalone)' ).matches );
    platform.isMobileSafari = platform.isIDevice && _ua.indexOf( 'Safari' ) > -1 && _ua.indexOf( 'CriOS' ) < 0;
    platform.isStandalone = platform.isInWebAppiOS || platform.isInWebAppChrome;
    platform.isiPad = ( platform.isMobileSafari && _ua.indexOf( 'iPad' ) > -1 );
    platform.isiPhone = ( platform.isMobileSafari && _ua.indexOf( 'iPad' ) === -1 );
    platform.isCompatible = ((platform.isChrome || platform.isMobileSafari || platform.isSamsung || platform.isFireFox || platform.isOpera) && (platform.isIDevice || platform.isAndroid || platform.isWindowsPhone) ) ;
    
    console.log("<small>"+JSON.stringify(platform).split(',').join('<br>')+"</small>");
}

function getPlatform( native ) {

   
    
    if(platform.isIDevice || platform.isAndroid || platform.isWindowsPhone){
        if ( platform.isInApp ) {
            return "InApp";
        }
        else if ( platform.isChrome && ( native === undefined && !native ) ) {
            return "native";
        } else if ( platform.isFireFox ) {
            return "firefox";
        } else if ( platform.isiPad ) {
            return "ipad";
        } else if ( platform.isiPhone ) {
            return "iphone";
        } else if ( platform.isOpera ) {
            return "opera";
        } else if ( platform.isSamsung ) {
            return "samsung";
        } else if ( platform.isEdge ) {
            return "edge";
        } else if ( platform.isChrome ) {
            return "Chrome";
        } else {
            return "undefined";
        }
    } else {
            return "computer";
    }
}


function isRunningStandalone() {
    return (window.matchMedia('(display-mode: standalone)').matches);
}

if (!isRunningStandalone()) {
    initInstalPromt();
}

function closeInstPromt(){
    console.log('close');
    document.querySelector('.installBlock_wrap').classList.remove('active');
}

function initInstalPromt(){ 
    

    let installBlock_wrap=document.createElement('DIV');
    installBlock_wrap.classList.add('installBlock_wrap');
    installBlock_wrap.innerHTML='<div class="container"><div class="close" on-click="closeInstPromt();">x</div><div class="logoWrap"></div> <div class="title">У нас появилось замечательное приложение</div><div class="subTitle">Скачайте его для удобного доступа к нашему сервису</div></div>';
    let container=installBlock_wrap.querySelector('.container');
    let installBlock=document.createElement('DIV');
    installBlock.classList.add('installBlock');
    installBlock_wrap.classList.add('active');
    let btnInstall=document.createElement('DIV');
    btnInstall.classList.add('installBtn');
    btnInstall.classList.add('colorBtn');
    btnInstall.setAttribute('id','install');
    // btnInstall.setAttribute('style','display:none;');
    btnInstall.innerHTML='Установить приложение';

    let installInfo=document.createElement('DIV');
    installInfo.setAttribute('id','instalApp_info');
    installInfo.classList.add('instalApp_info');
    installInfo.setAttribute('style','display:none;');
    container.appendChild(btnInstall);
    container.appendChild(installInfo);
    if(document.querySelector('.app_wrap'))document.querySelector('.app_wrap').appendChild(installBlock_wrap);

    // alert(window.navigator.standalone);
    checkPlatform();
    let target = getPlatform();

    let promptInst= {
        "native": {
            instruction: "Для установки откройте меню <span class='PWA_notification_icon'><svg xmlns='http://www.w3.org/2000/svg' width='512' height='512' viewBox='0 0 512 512'><title>ionicons-v5-f</title><circle cx='256' cy='256' r='48'/><circle cx='256' cy='416' r='48'/><circle cx='256' cy='96' r='48'/></svg></span> и коснитесь пункта «Добавить главный экран».",
        },
        "edge": {
            instruction: "Для установки коснитесь иконки <span class='PWA_notification_icon'><svg xmlns='http://www.w3.org/2000/svg' width='512' height='512' viewBox='0 0 512 512'><title>ionicons-v5-a</title><path d='M448,256c0-106-86-192-192-192S64,150,64,256s86,192,192,192S448,362,448,256Z' style='fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:32px'/><line x1='256' y1='176' x2='256' y2='336' style='fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px'/><line x1='336' y1='256' x2='176' y2='256' style='fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px'/></svg></span> «Добавить на главный экран».",

        },
        "chrome": {
            instruction: "Для установки откройте меню <span class='PWA_notification_icon'><svg xmlns='http://www.w3.org/2000/svg' width='512' height='512' viewBox='0 0 512 512'><title>ionicons-v5-f</title><circle cx='256' cy='256' r='48'/><circle cx='256' cy='416' r='48'/><circle cx='256' cy='96' r='48'/></svg></span> и коснитесь пункта «Добавить главный экран».",
            
        },
        "iphone": {
            instruction: "Для установки нажмите <span class='PWA_notification_icon'><svg xmlns='http://www.w3.org/2000/svg' width='512' height='512' viewBox='0 0 400 512'><path d='M336,192h40a40,40,0,0,1,40,40V424a40,40,0,0,1-40,40H136a40,40,0,0,1-40-40V232a40,40,0,0,1,40-40h40' style='fill:none;stroke:#0079ff;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px'/><polyline points='336 128 256 48 176 128' style='fill:none;stroke:#0079ff;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px'/><line x1='256' y1='321' x2='256' y2='48' style='fill:none;stroke:#0079ff;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px'/></svg></span>, затем коснитесь «Добавить на экран 'Домой'».",
        },
        "ipad": {
            instruction: "Для установки нажмите <span class='PWA_notification_icon'><svg xmlns='http://www.w3.org/2000/svg' width='512' height='512' viewBox='0 0 400 512'><path d='M336,192h40a40,40,0,0,1,40,40V424a40,40,0,0,1-40,40H136a40,40,0,0,1-40-40V232a40,40,0,0,1,40-40h40' style='fill:none;stroke:#0079ff;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px'/><polyline points='336 128 256 48 176 128' style='fill:none;stroke:#0079ff;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px'/><line x1='256' y1='321' x2='256' y2='48' style='fill:none;stroke:#0079ff;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px'/></svg></span>, затем коснитесь «Добавить на экран 'Домой'».",
        },
        "firefox": {
            instruction: "Для установки коснитесь иконки <span class='PWA_notification_icon'><svg xmlns='http://www.w3.org/2000/svg' width='512' height='512' viewBox='0 0 512 512'><title>ionicons-v5-a</title><path d='M448,256c0-106-86-192-192-192S64,150,64,256s86,192,192,192S448,362,448,256Z' style='fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:32px'/><line x1='256' y1='176' x2='256' y2='336' style='fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px'/><line x1='336' y1='256' x2='176' y2='256' style='fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px'/></svg></span> «Добавить на главный экран».",
            // https://2048.love2dev.com/imgs/firefox-a2hs-icon.png
        
        },
        "samsung": {
            instruction: "Для установки коснитесь иконки <span class='PWA_notification_icon'><svg xmlns='http://www.w3.org/2000/svg' width='512' height='512' viewBox='0 0 512 512'><title>ionicons-v5-a</title><path d='M448,256c0-106-86-192-192-192S64,150,64,256s86,192,192,192S448,362,448,256Z' style='fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:32px'/><line x1='256' y1='176' x2='256' y2='336' style='fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px'/><line x1='336' y1='256' x2='176' y2='256' style='fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px'/></svg></span> «Добавить на главный экран».",
            //   https://2048.love2dev.com/imgs/samsung-internet-a2hs-icon.png
            
        },
        "opera": {
            instruction: "Для установки откройте меню <span class='PWA_notification_icon'><svg xmlns='http://www.w3.org/2000/svg' width='512' height='512' viewBox='0 0 512 512'><title>ionicons-v5-f</title><circle cx='256' cy='256' r='48'/><circle cx='256' cy='416' r='48'/><circle cx='256' cy='96' r='48'/></svg></span> и коснитесь пункта «Добавить главный экран».",
        },
        "computer": {
            instruction: "Для установки приложения на смартфон - откройте страницу при помощи мобильного браузера(Safari, Chrome) и следуйте инструкциям",
        },
        "undefined": {
            instruction: "Для установки приложения на смартфон - откройте страницу при помощи мобильного браузера(Safari, Chrome) и следуйте инструкциям",
        }
    };


    console.log(promptInst);
    console.log(promptInst[ target ]);
    if(promptInst[ target ]){
        document.querySelector('#instalApp_info').innerHTML=promptInst[ target ].instruction;
    }



    let installPromptEvent;

    if('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/service-worker.js').then((registration) => {
        console.log('ServiceWorker successful, scope: ', registration.scope);
        }).catch((err) => {
        console.log('ServiceWorker failed: ', err);
        });
    }



    window.addEventListener('beforeinstallprompt', function (event) {
        event.preventDefault();
        installPromptEvent = event;
        btnInstall.style.display='block';
    });


    btnInstall.addEventListener('click', function () {
        console.log('click');
        btnInstall.setAttribute('disabled', '');
        btnInstall.style.display='none';
        if(installPromptEvent){
            installPromptEvent.prompt();
            installPromptEvent.userChoice.then((choice) => {
                if (choice.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                }
                installPromptEvent = null;
            });
        }else{
            installInfo.style.display='block';
        }
    });
    document.querySelector('.installBlock_wrap .close').addEventListener('click',closeInstPromt);
}