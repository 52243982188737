<template>
  <div>
      <div class="page_body">
        <div class="avto_reg_colorBlock colorBlock">
          <div class="page_sub_title">
              Поможем оформить пропуск <br> на МКАД, ТТК, СК с гарантией <br> от аннулирования
          </div>
          <ul class="std-list">
              <li class="std-list-item">
                  Временный пропуск в течении дня
              </li>
              <li class="std-list-item">
                  Оплата по факту
              </li>
              <li class="std-list-item">
                  Годовой за 14 рабочих дней
              </li>
              <li class="std-list-item">
                  СМС информирование
              </li>
          </ul>
      </div>
      <ul class="avto_reg_conts-list">
        <li class="avto_reg_conts-item">
          <a class="phone avto_reg_conts-link" href="tel:+74955007492">
              <div class="avto_reg_conts-iconWrap">
                <img class="avto_reg_conts-icon" src="../assets/img/11.png">
              </div>
              +7 (495) 500-74-92
            </a>
        </li>
        <li class="avto_reg_conts-item">
          <a class="mail avto_reg_conts-link">
              <div class="avto_reg_conts-iconWrap">
                <img class="avto_reg_conts-icon" src="../assets/img/33.png">
              </div>
              1494479@mail.ru
          </a>
        </li>
        <li class="avto_reg_conts-item">
          <a class="site avto_reg_conts-link" href="/">
              <div class="avto_reg_conts-iconWrap">
                <img class="avto_reg_conts-icon" src="../assets/img/icon-www.png">
              </div>
              openpolis.ru
            </a>
        </li>
      </ul>
      <div class="mess_block">
          <a href="viber://chat?number=%2B79255007492 " class="mess_block-link ">
              <img class="mess_block-icon" src="../assets/img/v.png">
          </a>
          <a href="whatsapp://send?phone=+79255007492" class="mess_block-link ">
              <img class="mess_block-icon" src="../assets/img/w.png">
          </a>
          <a href="tg://resolve?domain=test" class="mess_block-link ">
              <img class="mess_block-icon" src="../assets/img/t.png">
          </a>                                       
      </div>

        <a href="callback_page" class="colorBtn appInnerLink"  @click="toPageLink('callback_page',$event)">
          Связаться с нами
        </a>
      <form class="callBackForm" v-on:submit="senForm" style="display:none;">
            <div class="form-item">
                <label class="label">Ваше имя:</label>
                <input class="callBack-input" type="text" name="name">
            </div>
            <div class="form-item">
                <label class="label">Ваш телефон:</label>
                <input class="callBack-input" type="phone" name="phone">
            </div>
            <input type="submit" class="colorBtn" value="Отправить">
        </form>      
  </div>
  </div>
</template>

<script>
import store from '../store';
import axios from 'axios';
export default {
    store,
    axios,
    props: {
        
    },
    computed: {
                
    },
    data() {
      return {
        sitename:'https://openpolis.ru',
      }
    },    
    methods:{
      toPageLink(link,e) {

            e.preventDefault()
            console.log(e);
            // let href=this.getAttribute('href');
            console.log(link);
            // alert(href);
            store.commit('to_link_page', link);
            window.scrollTo({ top: 0 });
            if(document.querySelector('.app_wrap'))document.querySelector('.app_wrap').scrollTo({ top: 0}); 
            
        },      
      senForm( e ) {
          e.preventDefault();
          console.log(e.target);
          let formData = new FormData(e.target);
          axios.post( this.sitename + '/wp-json/avtopas/sendform/',
          {
              formData: formData
          })
          .then(function (response) {
              if ( response.status === 200 ) {
                  const data = response.data;
                  console.log(data);
              }
          })          
          .catch(function (error) {
              function strip_html_tags(str) {
                  if ((str===null) || (str==='')){
                      return false;
                  }else{    
                    str = str.toString();
                    return str.replace(/<[^>]*>/g, '');
                }
              }
              console.log(error);
              alert( strip_html_tags( error.response.data.message ) );
          });
      },
    }
}
</script>

<style>
    .avto_reg_colorBlock.colorBlock{
        padding:50px 30px;
        font-size:21px;
    }
    .avto_reg_conts-list{
      list-style:none;
      margin-bottom:60px;
      padding-left:0px;
    }
    .avto_reg_colorBlock .std-list-item{
      position: relative;
      padding-left: 40px;
      margin-bottom: 20px;
    }
   
    .avto_reg_conts-item{
      border-bottom: 1px solid #033481;
      padding: 24px 20px;
      font-size: 21px;
    }
    .avto_reg_conts-link{
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #000;
    }
    .avto_reg_conts-iconWrap{
        width: 40px;
        height: 40px;
        display: flex;
        justify-self: center;
        align-items: center;
        margin-right: 20px;
    }
    .avto_reg_conts-icon{
      width: 100%;
      height: 100%;
      -o-object-fit: contain;
      object-fit: contain;
    }
    /* .avto_reg_conts-link */
</style>