<template>
  <div>
        <form class="loginForm" v-on:submit="checkAvto3">
            <div class="colorBlock">
                <div class="page_sub_title">Введите серию и номер пропуска</div>
    
                <div class="avto_check_fields">
                  <div class="avto_reg_wrap">
                      <input class="avto_check_field avto_reg" 
                        v-model="polis_series" 
                        type="text" 
                        v-mask="customTokens"
                        :placeholder="polis_ser_pl" name="polis_series">
                    </div>
                    <!-- <dir class="avto_regl_label">
                      RUS <img class="profile_menu-icon" src="../assets/img/russia.png">
                    </dir> -->
                    -
                  <div class="avto_check_field_wrap">
                    <input 
                      class="avto_check_field"  
                      v-model="polis_number" 
                      type="text" 
                      :placeholder="polis_numb_pl" 
                      v-mask="'#######'"
                      name="polis_number">
                  </div>

                </div>
            </div>
            <input type="submit" class="colorBtn" value="Проверить пропуск">              
        </form>
        <button class="colorBtn subBtn" v-if="check_avto&&(active_user) " v-on:click="addPolices" >Сохранить найденный пропуск</button>
        <div class="check_avto_list emptyList">
          <h2>Пропуск не найден</h2>
        </div>
        <div class="check_avto_list emptyList insearch">
          <h2>Поиск</h2>
        </div>        
        <div class="check_avto_list" v-if="check_avto">
            <h2>Найденный пропуск:</h2>


            <div class="polis_list_item">
                <div class="polis_list_item-polisinfo">
                  <div class="label">Серия, номер:</div>
                  {{check_avto.polis_series}}-{{check_avto.polis_number}}
                </div>    
                <div class="polis_list_item-polisinfo">
                  <div class="label">Зона:</div>
                  <div class="polis_list_item-data_block">{{check_avto.polis_zone}}</div>
                </div>
                <div class="polis_list_item-polisinfo">
                  <div class="label">Срок действия:</div>
                  <div class="polis_list_item-data_block">c {{check_avto.polis_valid_from}} по {{check_avto.polis_valid_to}} (включительно)</div>
                </div>
                <div class="polis_list_item-polisinfo" v-if="check_avto.polis_cancel">
                    <div class="label">Дата анулирования:</div>
                    <div class="polis_list_item-data_block">{{check_avto.polis_cancel}}</div>
                  </div>                  
                <div class="polis_list_item-polisinfo imp">
                  <div class="label">Статус:</div>
                  <div class="polis_list_item-status">
                      {{check_avto.polis_status}}
                  </div>
                </div>
                <div class="polis_list_item-polisinfo imp" v-if="check_avto.leftDays>0">
                  <div class="label">Осталось дней:</div>
                  <div class="polis_list_item-days_block">
                    <div class="polis_list_item-lastDays"><div > {{check_avto.leftDays}}</div></div>
                  </div>
                </div>
      
                                                        
            </div>


            <!-- <div class="polis_list_item" v-for="polis in check_avto" :key="polis.passInfo" :class="{ new_item: polis.new_flag }">

               <div class="polis_list_item-header">
                 <div class="polis_list_item-avto">
                   <div class="polis_list_item-avto_number">{{avto_numb}}</div>
                   <div class="polis_list_item-avto_region">{{avto_pass_region}}</div>
                  </div>
                  <div class="polis_list_item-polis_zone">
                    {{polis.zone}}
                  </div>
                </div>
                <div class="polis_list_item-status">
                    {{polis.status}}
                  </div>
                  <div class="polis_list_item-polis-title">
                    <div class="polis_list_item-timeType"></div>
                    <div class="polis_list_item-polis_number">{{polis.passInfo}} {{polis.polisSeries}}</div>
                  </div>
                  <div class="polis_list_item-days_block">
                    <div class="polis_list_item-lastDays"><div v-if="polis.leftDays>0"> Осталось дней: {{polis.leftDays}}</div></div>
                    <div class="polis_list_item-period-type">{{polis.period}}</div>
                  </div>
                  <div class="polis_list_item-data_block">c {{polis.dateStart}} по {{polis.dateEnd}} (включительно)</div>
                                                        
            </div> -->



        </div>
        <button class="colorBtn subBtn" v-if="check_avto&&(active_user) " v-on:click="addPolices" >Сохранить найденный пропуск</button>
  </div>
</template>

<script>
import store from '../store';
import axios from 'axios';
import {mask} from 'vue-the-mask';
export default {
    store,
    axios,
    props: {
        
    },
    data() {
      return {
        sitename:'https://openpolis.ru',
        polis_number:'',
        polis_series:'',
        polis_numb_pl:'0000000',
        polis_ser_pl:'AA', 
        hexTokens: {
          F: {
            pattern: /[0-9a-fA-F]/,
            transform: v => v.toLocaleUpperCase()
          }
        },
        customTokens: {
            mask: 'ww',
            tokens: {
                'w': {
                    // pattern: /[а-яА-Я]/,
                    pattern: /[а,б,м,к,с,т,А,Б,М,К,С,Т]/,
                    transform: function(v) {
                        return v.toLocaleUpperCase();
                    }
                }
            }
        },
      }
    }, 
    directives: {mask},
    computed: {
      check_avto(){
        return store.state.check_avto;
      },
      this_page(){
        return store.state.active_page;
      },
      active_user(){
          return store.state.active_user;
      }
    },
    methods:{
        checkAvto( e ) {
            e.preventDefault();
            axios.post( this.sitename + '/wp-json/avtopas/checkavto',
            {
                avto_numb: this.avto_numb,
                avto_reg: this.avto_reg,
                user_id: this.active_user
            },{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                }    
            })
            .then(function (response) {
                if ( response.status === 200 ) {
                    const data = response.data;
                    this.check_avto = JSON.parse(data); 
                    console.log(JSON.parse(data));
                    
                }
            })          
            .catch(function (error) {
                function strip_html_tags(str) {
                    if ((str===null) || (str==='')){
                        return false;
                    }else{    
                        str = str.toString();
                        return str.replace(/<[^>]*>/g, '');
                    }
                }
                console.log(error);
                alert( strip_html_tags( error.response.data.message ) );
            });
        },
        checkAvto2( e ) {
                e.preventDefault();
                document.querySelector('.emptyList').classList.add('active');
                this.check_avto = false; 
                let url = 'https://openpolis.ru/wp-json/avtopas/checkavto?avto_numb='+this.avto_numb+'&avto_reg='+this.avto_reg;
                if(this.active_user){
                  url = url+'&user_id='+this.active_user.full_info.id;
                }
                let xhr = new XMLHttpRequest();
                // xhr.responseType = 'json';
                xhr.open("POST", url);
                xhr.send();

            xhr.onload = function() {
                let propusk=xhr.response;
                // console.log(propusk);
                propusk=propusk.replace('200','');
                
                let propuskData=JSON.parse(propusk);
                propuskData=JSON.parse(propuskData);
                console.log(propuskData);
                store.commit('set_checks_avto',propuskData);
                this.check_avto = propuskData; 
                document.querySelector('.emptyList').classList.remove('active');
                if(propuskData.length==0){
                  document.querySelector('.emptyList').classList.add('active');
                }
            };
        },
        checkAvto3( e ) {
                e.preventDefault();
                this.check_avto = false; 
                document.querySelector('.emptyList.insearch').classList.add('active');
                this.check_avto = false;                 
                let url = 'https://openpolis.ru/wp-json/avtopas/checkPolisByCode?polis_numb='+this.polis_number+'&polis_ser='+this.polis_series;
                if(this.active_user){
                  url = url+'&user_id='+this.active_user.full_info.id;
                }
                let xhr = new XMLHttpRequest();
                // xhr.responseType = 'json';
                xhr.open("POST", url);
                xhr.send();

            xhr.onload = function() {
                let propusk=xhr.response;
                if(propusk){

                  propusk=JSON.parse(propusk);
                  console.log(propusk.polis_valid_from);
                  // propusk=propusk.replace('200','');
                  
                  // propuskData=JSON.parse(propuskData);
                  // console.log(propuskData);
                  store.commit('set_checks_avto',propusk);
                  this.check_avto = propusk; 
                  document.querySelector('.emptyList').classList.remove('active');
                  if(propusk==0){
                    document.querySelector('.emptyList').classList.add('active');
                  }
                }else{
                  document.querySelector('.emptyList').classList.add('active');
                  store.commit('set_checks_avto',propusk);
                  this.check_avto = false; 
                }
                document.querySelector('.emptyList.insearch').classList.remove('active');
            };
        },        
        addPolices( e ) {
            e.preventDefault();
            if(this.check_avto.leftDays>0){

              const userURI = 'https://openpolis.ru/wp-json/avtopas/addusrpolis';
              axios({
                method: 'POST',
                      url: userURI,
                  data:{
                      user_id: this.active_user.full_info.id,
                      polises: this.check_avto,
                  },
                  headers: { 'Authorization': 'Bearer ' + this.active_user.short_info.token }
              }).then(function (response) {
                  if ( response.status === 200 ) {
                      const data = response.data;
                      console.log(data);
                      alert('Пропуск сохранён');
                      // if(!state.active_user){
                      //     let ob={};
                      //     ob.full_info=data;
                      //     state.active_user.full_info=ob;
                      // }else{
                        //     state.active_user.full_info=data;
                      // }
                      
                  }
              })          
              .catch(function (error) {
                function strip_html_tags(str) {
                      if ((str===null) || (str==='')){
                          return false;
                      }else{    
                          str = str.toString();
                          return str.replace(/<[^>]*>/g, '');
                      }
                  }
                  console.log(error);
                  alert( strip_html_tags( error.response.data.message ) );
              });
            }else{
              alert('Пропуск просрочен, нет смысла его сохранять');
            }
      }     
    }
}
</script>

<style>

  .callBack-input{
    color:#033481;
  }
  .check_avto_list-item{
    padding:10px;
    border:2px solid #033481;
    display:flex;
    flex-wrap:wrap;
  }
  .check_avto_list-item h3{
    width:100%;
  }
  .check_avto_list-item .std-label{
    margin-bottom:10px;
  }
  .char-value{
    color:#033481;
    font-size:19px;
  }
  .check_avto_list-cell{
    width:50%;
    width: 50%;
    margin-bottom: 30px;
  }
  .avto_check_fields {
      position: relative;
      border: 1px solid #fff;
      border-radius: 10px;
      font-size: 36px;
      display: flex;
      max-width: 350px;
      align-items: center;
      /* padding: 20px 10px; */
      margin: auto;
  }
  .avto_check_field:-internal-autofill-selected {
      background-color: #033481!important;
  }
  input.avto_check_field:-webkit-autofill,
  input.avto_check_field:-webkit-autofill:hover, 
  input.avto_check_field:-webkit-autofill:focus, 
  input.avto_check_field:-webkit-autofill:active{
      -webkit-background-clip: text;
      -webkit-text-fill-color: #ffffff;
      transition: background-color 5000s ease-in-out 0s;
      box-shadow: inset 0 0 20px 20px #23232329;
  }
  /* .avto_check_fields:before {
      width: 10px;
      content: '';
      height: 10px;
      position: absolute;
      top:calc(50% - 5px);
      left:10px;
      background-color: #fff;
      border-radius: 50%;
  }
  .avto_check_fields:after {
      width: 10px;
      content: '';
      height: 10px;
      position: absolute;
      top:calc(50% - 5px);
      right:10px;
      background-color: #fff;
      border-radius: 50%;
  } */
  .avto_check_field{
      background-color: transparent;
      font-size: 36px;
      color: #d7d8d9;
      font-weight: 200;
      width: 100%;
      text-align: right;
      padding-right: 15px;
      padding-left: 20px;
      border: none;
      padding: 20px 10px;
      height: 100%;
      box-sizing: border-box;
  }
  .avto_check_field_wrap{
      width: 60%;
  }
  .avto_reg{
      position: relative;
      width: 100%;
      border: none;
      text-align: center;
      padding-bottom: 20px;
      padding-top: 20px;
  }

  .avto_reg_wrap{
      /* border-left: 2px solid #fff!important; */
      position: relative;
      width: 40%;
      padding-right: 20px;
      text-align: center;
  }
   .avto_regl_label{
      position: absolute;
      padding-right: 30px;
      bottom: 3px;
      display: flex;
      font-size: 17px;
      align-items: center;
      right: 20px;
      width: max-content;
      margin: 0px;
  }
    .avto_regl_label img{
      height: 26px;
      margin-left: 7px;
    }
    .emptyList{display:none;}
    .emptyList.active{display:block;}

.polis_list_item-polisinfo{
    font-size: 19px;
    text-align: center;
    margin-bottom: 15px;
}
.polis_list_item-polisinfo .label{
    font-size: 14px;
    text-align: left;
    margin-bottom: 5px;
}
.polis_list_item-polisinfo.imp{
    width: 50%;
    font-size: 28px;
    display: inline-block;
    text-align: center;
    color: #033481;
}
.polis_list_item-polisinfo.imp .label{
  color: #3f6830;
}
</style>