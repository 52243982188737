<template>
  <div>
        <div class="page_body">
            <div class="notification_edit-list">
                <div class="notification_edit-item">
                    <div class="title">Уведомления на e-mail</div>
                    <div class="checkbox-wrap">
                        <input type="checkbox" class="checkbox" v-model="user_email_notifs" >
                        <div class="checkbox-icon"></div>
                    </div>
                </div>    
            </div>
        </div>
    </div>
</template>

<script>
import store from '../store';
export default {
    store,
    props: {
        
    },
    data() {
      return {
        avto_reg:false,
      }
    },    
    computed: {
        user_email_notifs:{
            get () {
                return store.state.active_user.full_info.acf.user_email_notif;
            },
            set (value) {
                store.commit('edit_email_notif', value);
            }
        },
        
    },
    methods:{
        // edit_email_notif(){
        //     console.log(this);
        //     // store.commit('edit_email_notif', index);
        // },
    }
}
</script>

<style>
    .notification_edit-list{
        background-color:#033481;
        padding: 0px 5px;
        color:#fff;
    }
    .notification_edit-item{
        border-bottom:1px solid #fff;
        display:flex;
        align-items:center;
        padding:30px 10px;
    }
    .notification_edit-item .title{
        font-size:21px;
        width:calc(100% - 68px);
    }
    .notification_edit-item  .checkbox-wrap{
        width:68px;
        position: relative;
    }
    .notification_edit-item  .checkbox{
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0px;
        top: 0px;
        opacity: 0.01;
        background-image: url(/assets/img/no-check.png);
    }
    .notification_edit-item  .checkbox-icon{
        border:2px solid #fff;
        width: 62px;
        height: 62px;
        background-image: url(/img/check.png);
        background-size: cover;
    }
    .notification_edit-item  .checkbox:checked+.checkbox-icon{
        background-color:grey;
    }
   
</style>