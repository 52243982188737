<template>
  <div>
        <div class="page_body">
            <div class="profil-data-block">
                <form name="profil-data-form" id="profil-data-form" v-on:submit="user_edit">
                    <div class="borderBlock">
                        <div class="profil-data-item">
                            <div class="profil-data-iconWrap">
                                <img class="profil-data-icon" src="../assets/img/44.png">
                            </div>
                            <div class="profil-data-body">
                                <input v-model="active_user.full_info.first_name" class="profil-data-item-value" type="text" >
                                <div class="profil-data-item-label">Фамилия, Имя</div>
                            </div>
                        </div>
                        <div class="profil-data-item">
                            <div class="profil-data-iconWrap">
                                <img class="profil-data-icon" src="../assets/img/11.png">
                            </div>
                            <div class="profil-data-body">
                                <input v-model="active_user.full_info.acf.user_phone" class="profil-data-item-value" type="text">
                                <div class="profil-data-item-label">Телефон</div>
                            </div>
                        </div>
                        <div class="profil-data-item">
                            <div class="profil-data-iconWrap">
                                <img class="profil-data-icon" src="../assets/img/33.png">
                            </div>
                            <div class="profil-data-body">
                                <input v-model="active_user.full_info.email" class="profil-data-item-value" type="text" >
                                <div class="profil-data-item-label">E-mail</div>
                            </div>
                        </div>
                        <div class="profil-data-item">
                            <div class="profil-data-iconWrap">
                                <img class="profil-data-icon" src="../assets/img/22.png">
                            </div>
                            <div class="profil-data-body">
                                <input v-model="active_user.full_info.acf.user_company" class="profil-data-item-value" type="text" >
                                <div class="profil-data-item-label">Компания</div>
                            </div>
                        </div>
                    </div>
                    <input type="submit" class="colorBtn" value="Сохранить">
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import store from '../store';
import axios from 'axios'
export default {
    store,
    axios,
    props: {
        
    },
    data() {
      return {
        sitename:'https://openpolis.ru',
      }
    },
    computed: {
        active_user(){
            return store.state.active_user;
        }
    },
    methods:{
        user_edit(e){
            e.preventDefault();
            axios.post( this.sitename + '/wp-json/avtopas/edituser',
            {
                user: this.active_user,
            })
            .then(function (response) {
                if ( response.status === 200 ) {
                    const data = response.data;
                    console.log(data);
                    if(data){
                        alert('Профиль изменён');
                    }
                }
            })          
            .catch(function (error) {
                function strip_html_tags(str) {
                    if ((str===null) || (str==='')){
                        return false;
                    }else{    
                        str = str.toString();
                        return str.replace(/<[^>]*>/g, '');
                    }
                }
                console.log(error);
                alert( strip_html_tags( error.response.data.message ) );
            });
        }
    }
}
</script>

<style>
    .profil-data-block{

    }
    .profil-data-block .borderBlock{
        border:4px solid #033481;
        padding:30px 15px;
        margin-bottom: 60px;
    }
    .profil-data-item{
        display:flex;
        margin-bottom:50px;
    }
    .profil-data-iconWrap{
        width:60px;
        height:60px;
        margin-right:20px;
    }
    .profil-data-icon{
        width: 100%;
        height: 100%;
        -o-object-fit: contain;
        object-fit: contain;
    }
    .profil-data-body{
        display:flex;
        flex-direction:column;
    }
    .profil-data-item-value{
        color:#033481;
        border:none;
        font-size:21px;
        margin-bottom:10px;
    }
    .profil-data-item-label{
        color:grey;
        font-size:18px;
    }
</style>