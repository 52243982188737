<template>
  <div>
        <div class="page_body">
            <div class="profil-data-block">
            
              <login_form></login_form>
            </div>
        </div>
    </div>
</template>

<script>

import login_form from '../components/login_form.vue';

export default {
    components:{
      login_form
    },
    props: {
        
    },
    data() {
      return {
        sitename:'https://openpolis.ru',
        login:'',
        password:'',
      }
    },
   
}
</script>