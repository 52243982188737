<template>
  <div>

        <div class="page_body" v-if="isLogged_user">
            <div class="page_sub_title" style="display:none;">
                Важно! Заполните в профиле эллектронную почту, чтобы всегда быть на связи с нами!
                <a class="colorBtn appInnerLink" href="profile_page"  @click="toPageLink('profile_page',$event)">Заполнить</a>
            </div>
            <div class="app-notif-string"></div>
            <!-- <div class="colorBtn installBtn" v-if="!appInstall" id="install"  @click="goInstall">Добавить приложение на рабочий стол</div> -->
            <div class="profile_menu_wrap colorBlock">
                <a class="phone" href="tel:+74955007492" >+7 (495) 500-74-92</a>
                <div class="mess_block">
                    <a href="viber://chat?number=%2B79255007492" class="mess_block-link ">
                        <img class="mess_block-icon" src="../assets/img/v.png">
                    </a>
                    <a href="whatsapp://send?phone=+79255007492" class="mess_block-link ">
                        <img class="mess_block-icon" src="../assets/img/w.png">
                    </a>
                    <a href="tg://resolve?domain=test" class="mess_block-link ">
                        <img class="mess_block-icon" src="../assets/img/t.png">
                    </a>
                </div>
                <ul class="profile_menu">
                    <li class="profile_menu-item">
                        <a href="profile_page" class="profile_menu-link appInnerLink"  @click="toPageLink('profile_page',$event)">
                            <div class="profile_menu-iconWrap">
                                <img class="profile_menu-icon" src="../assets/img/home.png">
                            </div>
                            <div class="profile_menu-item-text">
                                Мои данные
                            </div>
                        </a>
                    </li>
                    <li class="profile_menu-item">
                        <a href="notification_edit" class="profile_menu-link appInnerLink"  @click="toPageLink('notification_edit',$event)">
                            <div class="profile_menu-iconWrap">
                                <img class="profile_menu-icon" src="../assets/img/settings.png">
                            </div>
                            <div class="profile_menu-item-text">
                                Настройка уведомлений
                            </div>
                        </a>
                    </li>
                    <li class="profile_menu-item">
                        <a href="avto_lists_page" class="profile_menu-link appInnerLink"  @click="toPageLink('avto_lists_page',$event)">
                            <div class="profile_menu-iconWrap">
                                <img class="profile_menu-icon" src="../assets/img/search.png">
                            </div>
                            <div class="profile_menu-item-text">
                                Мои пропуска
                            </div>
                        </a>
                    </li>
                    <li class="profile_menu-item">
                        <a href="notifications_list" class="profile_menu-link appInnerLink"  @click="toPageLink('notifications_list',$event)">
                            <div class="profile_menu-iconWrap">
                                <img class="profile_menu-icon" src="../assets/img/ahtung.png">
                            </div>
                            <div class="profile_menu-item-text">
                                Уведомления
                                <span v-if="user_active_notifs">({{user_active_notifs}})</span>
                            </div>
                        </a>
                    </li>
                    <li class="profile_menu-item">
                        <a href="callback_page" class="profile_menu-link appInnerLink"  @click="toPageLink('callback_page',$event)">
                            <div class="profile_menu-iconWrap">
                                <img class="profile_menu-icon" src="../assets/img/star.png">
                            </div>
                            <div class="profile_menu-item-text">
                                Обратная связь
                            </div>
                        </a>
                    </li>
                    <li class="profile_menu-item" >
                        <a href="share_page" class="profile_menu-link appInnerLink"  @click="sharedInfo">
                            <div class="profile_menu-iconWrap">
                                <img class="profile_menu-icon" src="../assets/img/man.png">
                            </div>
                            <div class="profile_menu-item-text">
                                Рассказать друзьям
                            </div>
                        </a>
                    </li>
                    <li class="profile_menu-item">
                        <div class="profile_menu-iconWrap">
                            <img class="profile_menu-icon" src="../assets/img/close.png">
                        </div>
                        <div class="profile_menu-item-text">
                        <div v-on:click="logout" class="profile_menu-link">Выход</div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="page_body" v-else>
            <div class="std-tab">
                <div class="std_tab-header">
                    <div class="std_tab-header-item"
                    v-on:click="edit_auth_tab('login')"
                    :class="{active:open_auth_tab=='login'}"
                    >Войти</div>
                    <div class="std_tab-header-item"
                    v-on:click="edit_auth_tab('registr')"
                    :class="{active:open_auth_tab=='registr'}"
                    >Регистрация</div>
                </div>
                <div class="std_tab-body">
                    <div class="std_tab-body-item"  v-if:="open_auth_tab=='login'">
                        <h3>Войти</h3>
                        <login_form></login_form>
                    </div>
                    <div class="std_tab-body-item" v-if:="open_auth_tab=='registr'">
                        <h3>Регистрация</h3>
                        <registr_form></registr_form>
                    </div>                    
                </div>
            </div>
            
        </div>
  </div>
</template>

<script>
import store from '../store';
import axios from 'axios';
import login_form from '../components/login_form.vue';
import registr_form from '../components/registr_form.vue';

export default {
    store,
    axios,
    components:{
      login_form,
      registr_form
    },    
    data() {
      return {
        sitename:'https://openpolis.ru',
        login:'',
        password:'',
        active_tab:'login'
      }
    },
    computed: {
        isLogged_user() {
            console.log(store);
            return store.state.active_user;
        },
        open_auth_tab(){
            return store.state.open_auth_tab;
        },
        user_active_notifs(){
            if(store.state.active_user.full_info){
                if(store.state.active_user.full_info.acf.user_notifications){
                    let notifs=JSON.parse(store.state.active_user.full_info.acf.user_notifications);
                    let notViews=0
                    notifs.forEach(item => {
                        if(!item.view)notViews++;
                    });
                    return notViews;
                }else{
                    return false;
                }
            }else{
                return false;
            }

        },
        appInstall(){
            let isInstalled = localStorage.getItem('pwaInstalled') === '1' || false;
            if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true) {
                // User is currently navigating on the PWA so yes it's installed
                localStorage.setItem('pwaInstalled', '1');
                isInstalled = true;
            } else {
                //User is navigating in browser
                window.addEventListener('beforeinstallprompt', () => {
                    localStorage.setItem('pwaInstalled', '0');
                    isInstalled = false;
                    //User can get an installation prompt meaning the app is not installed
                });
                window.addEventListener('onappinstalled', () => {
                    localStorage.setItem('pwaInstalled', '1');
                    isInstalled = true;
                });
            }
            return isInstalled;
        }
    },    
     mounted() {
        let userInfo=JSON.parse(localStorage.getItem('userinfo'));
        console.log('userInfo');
        console.log(userInfo);
        store.commit('set_user',userInfo); 
        // const userURI = this.sitename + '/wp-json/avtopas/getUsrAvto';
        
        //   axios.post( userURI,
        //   {
        //       user_id: this.logged_user_id,
          
        // }).then(function (response) {
        //     if ( response.status === 200 ) {
        //         const data = response.data;
        //         // console.log(data);
        //         let avtos=JSON.parse(data);
        //         avtos=JSON.parse(avtos);
        //         // let avtos=data;
        //         console.log(avtos);
                
        //         avtos.forEach(avto => {
        //           let nowStamp=Date.now();
        //           let endStamp=new Date(avto.dateEnd).getTime();
        //           let perStamp=endStamp-nowStamp;
        //           console.log(avto.dateEnd);
        //           console.log(perStamp);
        //           let days=Math.floor(perStamp/86400000);
        //           console.log(days);
        //           avto.leftDays=days;
        //         });


        //         store.commit('set_avto_list',avtos);
        //         console.log('set_avto_list');
        //         document.querySelector('.app-notif-string').innerHTML='Идёт проверка статусов пропусков';
        //         setTimeout(() => { 
        //           document.querySelector('.app-notif-string').innerHTML='Идёт проверка статусов пропусков';
        //           console.log('check_polis_mosdata');
        //           store.commit('check_polis_mosdata',avtos); 
        //         }, 2000);
                 
        //     }
        // })
        // .catch(function (error) {
        //      console.log(error);
        // });

    },
    props: {
        
    },
    methods:{
        logout(){
            store.commit('loggout_user');
        },
        goInstall(){
            let installPromptEvent;
            installPromptEvent.prompt();
            installPromptEvent.userChoice.then((choice) => {
                if (choice.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                }
                installPromptEvent = null;
            });
        },
        edit_auth_tab(tab){
            store.commit('edit_auth_tab', tab);
        },
        toPageLink(link,e) {

            e.preventDefault()
            console.log(e);
            // let href=this.getAttribute('href');
            console.log(link);
            // alert(href);
            store.commit('to_link_page', link);
            window.scrollTo({ top: 0 });
            if(document.querySelector('.app_wrap'))document.querySelector('.app_wrap').scrollTo({ top: 0}); 
            
        },
        sharedInfo(e) {

            e.preventDefault()
            if (navigator.share) {
                console.log("Congrats! Your browser supports Web Share API")

                // navigator.share принимает объект с URL, title или text
                let data={};
                data.title="Openpolis.ru";
                data.text='Openpolis.ru. Оформление пропуска в Москву (МКАД, ТТК, СК)';
                data.url= window.location.href;
                navigator.share(data)
                    .then(function () {
                        console.log("Shareing successfull")
                    })
                    .catch(function (err) {
                        console.log('Unsuccessful share');
                        err.textContent = 'Share failed: ' + err.message;
                    })

            } else {
                console.log("Sorry! Your browser does not support Web Share API")
            }
            
        }        
    }
}

</script>

<style>
    
    .page_sub_title{
        font-size:21px;
        margin-bottom:20px;
        text-align:center;
        display:block;
    }
    .profile_menu{
        list-style:none;
        padding:12px 0px;
        margin: 0;
    }
    .profile_menu_wrap .phone{
        font-size: 35px;
        margin-bottom: 35px;
        text-align: center;
        color: #fff;
        display: block;
        text-decoration: none;
    }
    .profile_menu-item{
        text-align:left;
        font-size: 21px;
        display: flex;
        border-bottom:1px solid #fff;
        align-items: center;
    }
    .profile_menu-item:last-child{
        border:none;
    }
    .profile_menu-link{
        padding: 30px 0;
        display: flex;
        text-decoration: none;
        color: #fff;
        width: 100%;
        align-items: center;
    }
    .app_wrap_desc .profile_menu-link{
        padding: 10px;
    }
    .app_wrap_desc .profile_menu-link:hover{
        background-color: #012660;
    }    
    .profile_menu_wrap.colorBlock{
        padding: 70px 15px 50px;
        margin-bottom: 120px;
    }
    .app_wrap_desc .profile_menu_wrap.colorBlock{
        padding: 30px 15px 20px;
        margin-bottom: 120px;
    }
    .profile_menu-iconWrap{
        width: 45px;
        height: 45px;
        display: flex;
        justify-self: center;
        align-items: center;
        margin-right: 15px;
    }
    .profile_menu-icon{
        width: 100%;
        height: 100%;
        -o-object-fit: contain;
           object-fit: contain;
    }
    .installBtn{
        text-align: center;
        max-width: 100%;
        width: initial!important;
    }
    .app_wrap_desc .installBtn.colorBtn {
        width: initial!important;
     
    }
    .installBtn.colorBtn {
        text-align: center;
        max-width: 100%;
        cursor: pointer;
        font-size: 13px;
        padding: 10px;
        margin: 60px 0 10px;
    }
</style>