<template>
    <div>
        <div class="page_body">
            <form class="loginForm" v-on:submit="registUserSubmit">
                <div class="form-item">
                    <label class="std-label">Логин <span class="subText">(латиница)</span>:</label>
                    <input class="std-input"  v-model="login"
                    v-mask="['XXXXXXXXXXXXXXX']"
                     type="text" name="name" required>
                </div>
                <div class="form-item">
                    <label class="std-label">Email:</label>
                    <input class="std-input"  v-model="email" type="text" name="email" required>
                </div>  
                <div class="form-item">
                    <label class="std-label">Телефон:</label>
                    <input class="std-input"  
                    v-model="phone" 
                    v-mask="'+7##########'"
                    :placeholder="'+70000000000'"
                    type="text" 
                    required
                    name="phone">
                </div>                
                <div class="form-item">
                    <label class="std-label">Пароль:</label>
                    <input class="std-input" v-model="password" type="password" name="password" required>
                </div>
                <input type="submit" class="colorBtn" value="Зарегистрироваться">              
            </form>
        </div>
    </div>
</template>

<script>
import store from '../store';
import axios from 'axios'
import {mask} from 'vue-the-mask';
export default {
    store,
    axios,
    props: {
        
    },
    directives: {mask}, 
    data() {
      return {
        sitename:'https://openpolis.ru',
        login:'',
        password:'',
        phone:'',
        email:''
      }
    },
    computed: {
                
    },
    methods:{
      registUserSubmit( e ) {
          e.preventDefault();
          axios.post( this.sitename + '/wp-json/avtopas/adduser',
          {
              username: this.login,
              usermail: this.email,
              userphone: this.phone,
              password: this.password
          })
          .then(function (response) {
              if ( response.status === 200 ) {
                  const data = response.data;
                  console.log(data);
                  alert(data.resp);
                  store.commit('to_link_page', 'profile_menu_page');
                  if(data.stat){
                    store.commit('edit_auth_tab', 'login')
                  }
                  // this.get_full_user_info(data);
              }
          })          
          .catch(function (error) {
              function strip_html_tags(str) {
                  if ((str===null) || (str==='')){
                      return false;
                  }else{    
                    str = str.toString();
                    return str.replace(/<[^>]*>/g, '');
                }
              }
              console.log(error);
              alert( strip_html_tags( error.response.data.message ) );
          });
      }, 
      test() {
          alert(this.sitename + '/wp-json/jwt-auth/v1/token/');
      },      
    }
}
</script>