// import { createApp } from 'vue'
import { createStore } from 'vuex'
import axios from 'axios'

const store = createStore({
  state () {
    return {
      active_page: 'profile_menu_page',
      active_user: false,
      check_avto:false,
      app_mode:'mobile',
      refreshDataProcessing:false,
      open_auth_tab:'login',
      user_avtos:''
    }
  },
  actions:{
    change_app_mode(ctx,mode){
      ctx.commit('change_app_mode',mode);
    },
    link_page(ctx,next_page){
      ctx.commit('to_link_page',next_page);
    },
    logged_user (ctx,user) {
      ctx.commit('logged_user',user);
    },
    loggout_user (ctx) {
      ctx.commit('loggout_user');
    },
    set_user (ctx) {
      ctx.commit('set_user');
    },    
    get_full_user (ctx,user) {
      ctx.commit('get_full_user',user);
    },
    set_checks_avto (ctx,avto) {
      ctx.commit('set_checks_avto',avto);
    },
    set_avto_list (ctx,avtos) {
      ctx.commit('set_checks_avto',avtos);
    }, 
    ses_notif(ctx,notif_inex) {
      ctx.commit('ses_notif',notif_inex);
    },
    dell_notif(ctx,notif_inex) {
      ctx.commit('dell_notif',notif_inex);
    },    
    check_polis_mosdata(ctx,avto) {
      ctx.commit('check_polis_mosdata',avto);
    }, 
    refresh_avto_list(ctx,avto) {
      ctx.commit('refresh_avto_list',avto);
    }, 
    edit_hars_user(ctx,user,harname) {
      ctx.commit('edit_hars_user',user,harname);
    },
    edit_email_notif(ctx,mail_edit_val) {
      ctx.commit('edit_email_notif',mail_edit_val);
    },
    edit_auth_tab(ctx) {
      ctx.commit('edit_auth_tab');
    },
    set_refresh_data_mode(ctx,modname){
      ctx.commit('set_refresh_data_mode',modname);
    }
  },
  mutations: {
    initialiseStore(state) {
      // Check if the ID exists
      if(localStorage.getItem('vuex')) {
        // Replace the state object with the stored item
        this.replaceState(
          Object.assign(state, JSON.parse(localStorage.getItem('vuex')))
        );
      }
    },
    change_app_mode (state,mode) {
      state.app_mode=mode;
    },
    to_link_page (state,next_page) {
      state.active_page=next_page;
    },
    logged_user (state,user) {
      let data={};
      data.short_info=user;
      state.active_user=data;
      const userURI = 'https://openpolis.ru/wp-json/wp/v2/users/me';
      axios({
          method: 'POST',
          url: userURI,
          headers: { 'Authorization': 'Bearer ' + user.token }
      }).then(function (response) {
          if ( response.status === 200 ) {
              const data = response.data;
              console.log(data);
              if(!state.active_user){
                let ob={};
                ob.full_info=data;
                state.active_user=ob;
              }else{
                state.active_user.full_info=data;
              }
              localStorage.setItem('userinfo', JSON.stringify(state.active_user));
          }else{
            store.state.active_user=false;
          }
      })
    },
    set_user (state,userdata){
      state.active_user=userdata;
    },
    get_full_user (state,user) {
      
      const userURI = 'https://openpolis.ru/wp-json/wp/v2/users/me';
      axios({
          method: 'POST',
          url: userURI,
          headers: { 'Authorization': 'Bearer ' + user.token }
      }).then(function (response) {
          if ( response.status === 200 ) {
              const data = response.data;
              console.log(data);
              if(!state.active_user){
                let ob={};
                ob.full_info=data;
                state.active_user.full_info=ob;
              }else{
                state.active_user.full_info=data;
              }
              
          }else{
            store.state.active_user=false;
          }
      })
    },
    edit_hars_user (state,payload) {
      
      // console.log(user);
      axios.post( 'https://openpolis.ru/wp-json/avtopas/editharsuser',
      {
        payload: payload,
      },
      {headers: { 'Authorization': 'Bearer ' + payload.userInfo.short_info.token }}    
      )
      .then(function (response) {
          if ( response.status === 200 ) {
              const data = response.data;
              console.log(JSON.parse(data));
              console.log(state);
              
          }
      })          
      .catch(function (error) {
          function strip_html_tags(str) {
              if ((str===null) || (str==='')){
                  return false;
              }else{    
                  str = str.toString();
                  return str.replace(/<[^>]*>/g, '');
              }
          }
          console.log(error);
          alert( strip_html_tags( error.response.data.message ) );
      });
      
    },        

    loggout_user (state) {
      state.active_user=false;
      localStorage.setItem('userinfo',false);
    },
    set_checks_avto (state,avto) {
      state.check_avto=avto;
    },
    set_avto_list (state,avtos) {
      let out=[];
      avtos.forEach(avto => {
       if( avto.leftDays>0){
        out.push(avto);
       }
      }); 
      state.user_avtos=out;

    },
    ses_notif(state,notif_inex){
      let notifList=state.active_user.full_info.acf.user_notifications;
      notifList=JSON.parse(notifList);
      notifList[notif_inex].view=true;
      state.active_user.full_info.acf.user_notifications=JSON.stringify(notifList);


      let payload={
        userInfo:state.active_user,
        harname:'user_notifications'
      };
      store.commit('edit_hars_user', payload);

    },
    dell_notif(state,notif_inex){
      let notifList=state.active_user.full_info.acf.user_notifications;
      notifList=JSON.parse(notifList);
      notifList.splice(notif_inex, 1);
      state.active_user.full_info.acf.user_notifications=JSON.stringify(notifList);
      let payload={
        userInfo:state.active_user,
        harname:'user_notifications'
      };
      store.commit('edit_hars_user', payload);

    },    
    check_polis_mosdata(state,avto){

      state.refreshDataProcessing=true;
      // alert('check');

      const userURI = 'https://openpolis.ru/wp-json/avtopas/checkPolis';
        
      axios.post( userURI,
      {
          policies: avto,
      
      }).then(function (response) {
          if ( response.status === 200 ) {
              const data = response.data;
              // console.log(data);
              let avtos=JSON.parse(data);
              avtos=JSON.parse(avtos);
              // let avtos=data;
              console.log(avtos);
              state.refreshDataProcessing=false;
              store.commit('refresh_avto_list',avtos);
              
              store.commit('logged_user', state.active_user.short_info);
              // store.commit('set_avto_list',avtos);
              // setTimeout(() => { store.commit('check_polis_mosdata',avtos); }, 2000);
              
          }
      })
      .catch(function (error) {
          console.log(error);
      });
    },
    refresh_avto_list(state,newData){
      let oldData=state.user_avtos;
      let newDataObj={};
      newData.forEach(item => {
        newDataObj[item.number]=item;
      });
      let changeFlag=false;
      console.log(newData);      
      oldData.forEach(item => {
        if(item.status!=newDataObj[item.passInfo].passStatus)changeFlag=true;
        item.status=newDataObj[item.passInfo].passStatus;
        item.polisCanceledData=newDataObj[item.passInfo].canceledDate;
      });
      if(changeFlag) alert('Найдены изменения');

    },
    edit_email_notif(state,mail_edit_val){
      state.active_user.full_info.acf.user_email_notif=mail_edit_val;

      console.log('edit_email_notif');
      let payload={
        userInfo:state.active_user,
        harname:'edit_email_notif'
      };
      store.commit('edit_hars_user', payload);

    },
    edit_auth_tab(state,tab){
        state.open_auth_tab=tab
    },
    set_refresh_data_mode(state,modname){
      state.refreshDataProcessing=modname;
    },
  },
  getters: {
    get_active_user (state) {
      return state.active_user;
    }
  }  
})
export default store;